/**
 * Module dependencies
 */
import React, { useEffect } from 'react';
import { string } from 'prop-types';

import { LazyHydrate as Hydrator, loadable } from 'nordic/lazy';
import classnames from 'classnames';

import Search from './main';
import Header from '../../../../../components-v2/header';
import Banner from '../../../../../components/banner/banner.desktop';
import TopKeywordsContainer from '../../../../../components/top-keywords/top-keywords.desktop';
import SideBar from '../../../../../components/sidebar/sidebar.desktop';
import { PaginationDesktop } from '../../../../../components-v2/pagination/index';
import ViewOptionsContainer from '../../../../../components/sidebar/components/view-options/view-options.desktop';
import Billboard from '../../../../../components/billboard/chunk-billboard.desktop';
import LayoutManagerContainer from '../../../../../components/layout/manager.desktop';
import SnackBar from '../../../../../components/snackbar/container';
import Carousel from '../../../../../components/carousel-search/chunk-carousel.desktop';
import CarouselAndes from '../../../../../components/carousel-andes-search/chunk-carousel.desktop';
import CrossSiteModal from '../../../../../components/cross-site-modal/chunk-cross-site';
import BottomAds from '../../../../../components/bottom-ads/bottom-ads';
import ListingDisclaimer from '../../../../../components/listing-disclaimer/chunk-listing-disclaimer.mobile';
import FooterDisclaimer from '../../../../../components/footer-disclaimer/chunk-footer-disclaimer';
import VehicleBodySelectorContainer from '../../../../../components/vehicle-body-selector/vehicle-body-selector';
import ZrpDisclaimerContainer from '../../../../../components/zrp-disclaimer/chunk-zrp-disclaimer';
import FullShipping from '../../../../../components/full-snackbar/full-freeshipping.desktop';
import FullFreeshippingSwitcher from '../../../../../components/full-snackbar/full-freeshipping-switcher';
import PricingBanner from '../../../../../components-v2/pricing-banner/chunk-pricing-banner';
import BoletoDiscountMessage from '../../../../../components/boleto-discount-message/chunk-discount-message';
import ValuePropositionsDetails from '../../../../../components/value-propositions-details/chunk-propositions-details';
import HeaderComponent from '../../../../../components/header-component';
import FooterComponents from '../../../../../components-v2/footer-components';
import CallToActionPage from '../../../../../components/call-to-action-page/chunk-call-to-action-page';
import useElementSmoothScroll from '../../../../../hooks/use-element-smooth-scroll';
import { trackSellerPixelsInfo } from '../../../../../lib/tracking';
import { HEADER_TYPES } from '../../../../../components-v2/header/utils/constants';
import { MAIN_VARIANTS } from './constants';
import { LAYOUTS } from '../../../../../constants';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const CPGDesktop = loadable(() => import('../../../../../components/cpg-nordic/desktop'));

const namespace = 'ui-search-main';
const sectionNamespace = 'ui-search-results';

const getMainVariant = {
  [HEADER_TYPES.OFFICIAL_STORE]: MAIN_VARIANTS.OFFICIAL_STORE,
  [HEADER_TYPES.DEAL]: MAIN_VARIANTS.DEALS,
  [HEADER_TYPES.EXHIBITOR]: MAIN_VARIANTS.EXHIBITOR,
};

const getSectionVariationClass = (initialState, isShops) => {
  if (initialState.layout_options.current === LAYOUTS.TYPE_MAP) {
    return `${sectionNamespace}--map`;
  }

  if (initialState.listing_disclaimer) {
    return classnames(sectionNamespace, `${sectionNamespace}--without-disclaimer`, {
      'shops__search-results': isShops,
    });
  }

  return sectionNamespace;
};

/**
 * View Component
 */
const SearchDesktop = ({ baseURL, ...rest }) => {
  const { initialState } = rest;
  const { shops, carousel, billboard, vehicle_body_selector, sidebar } = initialState;
  const isShops = Boolean(shops);
  const headerType = initialState?.header?.type;
  const mainVariant = getMainVariant[headerType] || null;
  const sectionClass = getSectionVariationClass(rest.initialState, isShops);
  const withoutHeaderClass = rest.initialState.top_keywords ? '' : `${namespace}--without-header`;
  const onlyProductsItemsClass = carousel || billboard || vehicle_body_selector ? '' : `${namespace}--only-products`;
  const withoutBreadcrumbClass = sidebar?.components?.find((c) => c.type === 'BREADCRUMB')
    ? ''
    : `${namespace}--without-breadcrumb`;
  const isMapLayout = initialState.layout_options.current === LAYOUTS.TYPE_MAP;
  const withTopKeyWords = rest.initialState.top_keywords ? `${namespace}--with-topkeywords` : '';

  const container = useElementSmoothScroll(isMapLayout);

  useEffect(() => {
    if (shops && window.tracking_mediator) {
      trackSellerPixelsInfo(window.tracking_mediator, initialState.results);
    }
  }, [initialState.results, shops]);

  return (
    <Search baseURL={baseURL} {...rest} platformId="STD">
      <FullFreeshippingSwitcher
        defaultComponent={<FullShipping disabledByCookie={initialState.fullFreeShippingDisabled} />}
      />

      {initialState.cpg_header && <CPGDesktop {...initialState.cpg_header} />}
      <Hydrator whenVisible>
        <Header />
      </Hydrator>
      {!isMapLayout && <TopKeywordsContainer />}

      <div
        className={classnames(
          namespace,
          mainVariant && `${namespace}--${mainVariant}`,
          withoutHeaderClass,
          withoutBreadcrumbClass,
          onlyProductsItemsClass,
          withTopKeyWords,
          { 'shops__search-main': isShops },
        )}
      >
        <SideBar />
        <section className={sectionClass} ref={container}>
          <Hydrator whenVisible>
            <HeaderComponent />
          </Hydrator>
          <ViewOptionsContainer key={`${namespace}-sort-filter`} />
          <Banner />
          <Hydrator whenVisible>{carousel && (carousel.type === 'CPG' ? <CarouselAndes /> : <Carousel />)}</Hydrator>
          <Hydrator whenVisible>
            <ZrpDisclaimerContainer />
          </Hydrator>
          <Hydrator whenVisible>
            <Billboard />
          </Hydrator>
          <VehicleBodySelectorContainer />
          <Hydrator ssrOnly>
            <PricingBanner />
          </Hydrator>
          <Hydrator whenVisible>
            <BoletoDiscountMessage />
          </Hydrator>
          <LayoutManagerContainer />
          <Hydrator ssrOnly>
            <CallToActionPage />
          </Hydrator>
          <PaginationDesktop />
          <Hydrator ssrOnly>
            <ListingDisclaimer />
          </Hydrator>
          <Hydrator ssrOnly>
            <FooterDisclaimer />
          </Hydrator>
          <SnackBar />
          <Hydrator whenVisible>
            <CrossSiteModal />
          </Hydrator>
          <Hydrator whenVisible>
            <ValuePropositionsDetails />
          </Hydrator>
        </section>
      </div>
      {isMapLayout && <TopKeywordsContainer />}
      <BottomAds />
      <FooterComponents />
    </Search>
  );
};

SearchDesktop.propTypes = {
  baseURL: string.isRequired,
};

/**
 * Inject i18n context as props into View.
 */
export default SearchDesktop;
